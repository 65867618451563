import React from "react"

export default function() {
  return (
    <section style={{margin: 0}}>
        <div
          className="col-md-12"
          style={{background: "#58b0da29",color: "#443636",paddingTop: "3em",paddingBottom: "2em"}}
        >
          <div style={{width: "100%"}}>
              <p>
              Sheer Analytics and Insights offers a variety of data analytics services that help businesses gain insights from their data. These services help businesses make informed decisions, optimize their operations, and stay ahead of the competition. With data becoming increasingly important in today’s business landscape, the services offered by Sheer Analytics and Insights are essential for businesses that want to stay competitive and succeed in the digital age. Sheer Analytics and Insights helps businesses make data-driven decisions by providing a variety of data analytics services.
              </p>
              <br />
              <h1>Data Visualization</h1>
              <p>Data visualization is the graphical representation of data and information. It helps businesses understand complex data sets and identify trends, patterns, and relationships. Sheer Analytics and Insights offers data visualization services that help businesses make sense of their data. They use various visualization tools, such as charts, graphs, and dashboards, to present data in a meaningful and easy-to-understand way. This helps businesses quickly identify trends and patterns, which can be used to inform decision-making.</p>
              <h1>Data Mining</h1>
              <p>Data mining is the process of discovering patterns in large data sets. It involves analyzing data from different sources to identify trends, patterns, and relationships. Sheer Analytics and Insights offers data mining services that help businesses uncover insights from their data. They use advanced analytics techniques, such as clustering, regression, and decision trees, to identify patterns in data sets. This helps businesses identify opportunities for growth and optimize their operations.</p>
              <h1>Predictive Analytics</h1>
              <p>Predictive analytics is the use of statistical algorithms and machine learning techniques to identify patterns and predict future outcomes. Sheer Analytics and Insights offers predictive analytics services that help businesses anticipate future trends and make proactive decisions. They use data modeling techniques, such as regression analysis and decision trees, to forecast future outcomes based on historical data. This helps businesses make informed decisions and plan.</p>
              <h1>Text Analytics</h1>
              <p>Text analytics is the process of extracting information from unstructured data, such as social media posts, customer feedback, and email communications. Sheer Analytics and Insights offers text analytics services that help businesses gain insights from unstructured data. They use natural language processing (NLP) techniques to analyze text data and identify sentiment, topics, and trends. This helps businesses understand customer needs and preferences and make data-driven decisions.</p>
              <h1>Data Integration</h1>
              <p>Data integration is the process of combining data from different sources to create a unified view of information. Sheer Analytics and Insights offers data integration services that help businesses streamline their operations and improve efficiency. They use data integration tools to combine data from different sources, such as customer databases, financial systems, and marketing platforms. This helps businesses get a holistic view of their operations, which can be used to make informed decisions.</p>
          </div>
          <p>For any queries reach us at <a href="mailto:datadiscover@sheeranalyticsandinsights.com">datadiscover@sheeranalyticsandinsights.com</a></p>
        </div>
    </section>
  )
}
